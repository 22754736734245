import { currentUser, isAuthGuardActive,apiUrl,headers } from '../../constants/config'
import { setCurrentUser,set_token,getCurrentUser } from '../../utils'
import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "@/constants/config";
import Vue from 'vue';
import axios from 'axios';

export default {
  state: {
    currentUser: {},
    currentToken: "",
    loginError: null,
    registerError:null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null,
    token: null,
  },
  getters: {
    currentUser: state => state.currentUser,
    currentToken: state => state.currentToken,
    processing: state => state.processing,
    loginError: state => state.loginError,
    registerError: state => state.registerError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.auth.token = token;
    },
    CLEAR_TOKEN(state) {
        state.auth.token = null;
    },
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    settoken(state, payload) {
      
      state.currentToken = payload
      state.processing = false
      state.loginError = null
    },
    
    setUserFromRegistration(state, payload) {
      state.currentUser = payload
    },
    setLogout(state) {
      state.currentUser = null,
      state.currentToken =  null,
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setRegisterError(state, payload) {
      state.registerError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = true
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError(state) {
      state.loginError = null
    }
  },
  actions: {
    async login({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)    
      await axios
      .post(`${apiUrl}/auth/login`, payload)
        .then((res) => {
          const data = res.data;
          if (res.status === 200) {
            if (data.status == true)
            {
                  const profile = data.user;
                  const token = data.token
                  set_token(token)
                  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                  commit('settoken', token)
                  setCurrentUser(profile)
                  commit('setUser', profile)
                 
            }
            else
            {
              setCurrentUser(null);
              commit('setError', data.message)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
            }
        } 
        else {
          setCurrentUser(null);
          commit('setError', "something went wrong")
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      }) 
      .catch((err) => {
        setCurrentUser(null);
        commit('setError',err)
        setTimeout(() => {
          commit('clearError')
        }, 3000)
      });
    },
    register({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)    
      axios
      .post(`${apiUrl}/auth/register`, payload)
        .then((res) => {
          const data = res.data;
          if (res.status === 200) {
            if (data.status == true)
            {
                  const profile = data.user;
                  const token = data.token
                  set_token(token)
                  commit('settoken', token)
                  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                  setCurrentUser(profile)
                  commit('setUser', profile)
            }
            else
            {
              setCurrentUser(null);
              commit('setRegisterError', data.message)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
            }
        } 
        else {
          setCurrentUser(null);
          commit('setRegisterError', "something went wrong")
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      }) 
      .catch((err) => {
        ;
        setCurrentUser(null);
        commit('setRegisterError',err +"<br/>"+err.response.data.message)
        setTimeout(() => {
          commit('clearError')
        }, 3000)
      });
    },
    forgotPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      axios
      .post(`${apiUrl}/auth/forget-password`, payload)
      .then((res) => {
        if (res.status === 200) {
          
          commit('clearError')
          commit('setForgotMailSuccess')
          Vue.$toast.success(`Email Sent, Please check your email!`)
        }
        else {
          commit('setError', "something went wrong")
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      }) 
      .catch((err) => {
        commit('setError',"something went wrong")
        setTimeout(() => {
          commit('clearError')
        }, 3000)
      });
     },
    resetPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      axios
      .post(`${apiUrl}/auth/reset-password`, payload)
      .then((res) => {
        if (res.status === 200) {
          commit('clearError')
          commit('setResetPasswordSuccess')
          Vue.$toast.success(`Pzssword Reset Successfull`)
        }
        else {
          commit('setError', "something went wrong")
          setTimeout(() => {
            commit('clearError')
          }, 3000)
        }
      }) 
      .catch((err) => {
        commit('setError',"something went wrong")
        setTimeout(() => {
          commit('clearError')
        }, 3000)
      });
    },
    signOut({ commit }) {
      axios
      .get(`${apiUrl}/auth/logout`)
      .then((resp) => {
          if(resp.data.status ==  true){
            delete axios.defaults.headers.common['Authorization'];
            setCurrentUser(null);
            set_token(null);
            commit('setLogout')
            localStorage.clear();
          }
        }
      )
    },
    setRegisteredUser({ commit }, payload) {
      commit('setUserFromRegistration', payload)
    },
    clearRegisteredUser({ commit }) {
      commit('setLogout')
    }
  }
}
